<template>
  <section>
    <b-card>
      <h3 class="mb-1 font-weight-bolder">
        {{ $t('Global.Filters') }}
      </h3>
      <div class="row justify-content-between">
        <b-col lg="3" md="4" sm="12">
          <label>
            {{ $t('Management.Employees.Status') }}
          </label>
          <v-select
            v-model="filter.active"
            :placeholder="$t('Global.Filter')"
            :options="[status.active, status.inactive]"
          >
            <template #option="{ label }">
              <div>{{ $t(`Management.Employees.${label}`) }}</div>
            </template>
            <template #selected-option="{ label }">
              <div>{{ $t(`Management.Employees.${label}`) }}</div>
            </template>
          </v-select>
        </b-col>
        <b-col lg="3" md="4" sm="12">
          <label>
            {{ $t('Management.Employees.Contract.Contract') }}
          </label>
          <v-select
            v-model="filter.contract"
            :placeholder="$t('Global.Filter')"
            :options="employeeContractOptions"
          />
        </b-col>
      </div>
    </b-card>
    <b-card no-body>
      <div class="d-flex custom-search row mb-2 px-2 pt-2 align-items-center">
        <div class="col-md-6">
          <h2 class="m-0 font-weight-bolder">
            {{ $t('Global.All') }} {{ $t('Management.Employees.Employees') }}
          </h2>
        </div>
        <div class="col-md-6 d-md-flex justify-content-end">
          <b-form-input
            v-model="searchTerm"
            :placeholder="$t('Global.Search')"
            type="text"
            class="d-inline-block search"
          />
        </div>
      </div>
      <Table
        :rows="rowData"
        :search-term="searchTerm"
        @reset-app-key="resetAppKey"
        @toggle-app-access="toggleAppAccess"
      />
    </b-card>
  </section>
</template>

<script>
import { BFormInput, BCard, BCol } from 'bootstrap-vue'
import contracts from '@/views/loggedin/management/employees/utility/contracts'
import vSelect from 'vue-select'

import Table from './components/table.vue'

export default {
  components: {
    BFormInput,
    BCard,
    BCol,
    vSelect,
    Table,
  },

  data: () => ({
    filter: {
      active: 'Active',
      contract: null,
    },
    searchTerm: '',
    rows: [],
    status: {
      active: 'Active',
      inactive: 'Inactive',
    },
  }),

  computed: {
    rowData() {
      return this.rows
        .filter(x => {
          if (this.filter.active === null) return true
          const state = this.filter.active === this.status.active
          return state === x.active
        })
        .filter(x => {
          if (this.filter.contract === null) return true
          return x.contract === this.filter.contract.key
        })
    },
    employeeContractOptions() {
      const options = Object.keys(contracts).map(key => ({
        label: this.$t(`Management.Employees.Contract.Types.${key}`),
        key,
      }))
      return options
    },
  },

  created() {
    this.load()
  },

  methods: {
    async load() {
      try {
        const { data } = await this.$axios.get('employees')
        this.rows = data
      } catch (error) {
        this.$alert.error()
      }
    },
    async toggleAppAccess(row) {
      const subject = `${row.username} - ${row.firstname} ${row.lastname}`

      const messageVNode = this.$createElement('div', {
        class: ['p-1'],
        domProps: {
          innerHTML: this.$t(
            `Management.Employees.App${
              row.appAccess ? 'ActiveText' : 'DeactivateText'
            }`,
            { subject },
          ),
          style: 'font-size: 16px',
        },
      })

      const title = `${this.$t('Management.Employees.Employees')} ${this.$t(
        `Management.Employees.To${row.appAccess ? 'Active' : 'Deactivate'}`,
      )
        .toString()
        .toLowerCase()}`

      const choice = await this.$bvModal.msgBoxConfirm([messageVNode], {
        title,
        size: 'sm',
        okVariant: 'primary',
        cancelVariant: 'outline-secondary',
        okTitle: this.$t(
          `Management.Employees.To${row.appAccess ? 'Active' : 'Deactivate'}`,
        ),
        cancelTitle: this.$t('Global.Retreat'),
        hideHeaderClose: false,
        centered: true,
        'body-class': 'p-2',
      })
      if (!choice) {
        this.setPropertyFormEmployee(row.id, 'appAccess', !row.appAccess)
        return
      }

      try {
        await this.$axios.patch(`employees/${row.id}`, {
          appAccess: row.appAccess,
        })
      } catch {
        this.$alert.error()
        this.setPropertyFormEmployee(row.id, 'appAccess', !row.appAccess)
      }
    },
    setPropertyFormEmployee(id, key, value) {
      this.rows = this.rows.map(x => {
        if (x.id !== id) return x
        return {
          ...x,
          [key]: value,
        }
      })
    },
    resetAppKey(id) {
      this.setPropertyFormEmployee(id, 'hasAppKey', false)
    },
  },
}
</script>
