<!-- eslint-disable vue/no-v-html -->
<template>
  <!-- table -->
  <vue-good-table
    :columns="columns"
    :rows="rows"
    :search-options="{
      enabled: true,
      externalQuery: searchTerm,
      searchFn: searchFunc,
    }"
    :pagination-options="{
      enabled: true,
      perPage: pageLength,
    }"
    :row-style-class="row => (row.active === false ? 'muted-row' : null)"
  >
    <div slot="emptystate" class="text-center">
      {{ $t('Global.Emptystate') }}
    </div>
    <template slot="table-row" slot-scope="props">
      <span v-if="props.column.field === 'contract'">
        <span v-html="formatContract(props.row)" />
      </span>
      <span v-else-if="props.column.field === 'active'">
        <span
          :style="{
            color: `var(--${props.row.active ? 'success' : 'danger'})`,
          }"
        >
          {{
            $t(
              `Management.Employees.${
                props.row.active ? 'Active' : 'Inactive'
              }`,
            )
          }}
        </span>
      </span>
      <span v-else-if="props.column.field === 'appAccess'" class="appAccess">
        <b-form-checkbox
          v-model="props.row.appAccess"
          class="custom-control-success"
          name="check-button"
          switch
          @change="$emit('toggle-app-access', props.row)"
        >
          <span class="switch-icon-left">
            <feather-icon icon="UnlockIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="LockIcon" />
          </span>
        </b-form-checkbox>
      </span>
      <span v-else-if="props.column.field === 'action'" class="action">
        <span
          v-b-tooltip.hover.top="
            $t(
              `Global.${
                $can($acl.action.Update, $acl.subjects.Employees)
                  ? 'EditSubject'
                  : 'ViewSubject'
              }`,
              { subject: $t('Management.Employees.Employees') },
            )
          "
        >
          <feather-icon
            :icon="
              $can($acl.action.Employees, $acl.subjects.Employees)
                ? 'Edit2Icon'
                : 'EyeIcon'
            "
            size="16"
            class="hover-green"
            @click="edit(props.row.id)"
          />
        </span>
        <span
          v-if="
            $can($acl.action.Update, $acl.subjects.Employees) &&
            props.row.appAccess
          "
        >
          <span
            v-if="props.row.hasAppKey"
            v-b-tooltip.hover.top="$t('Management.Employees.ResetAppKey')"
          >
            <feather-icon
              icon="SmartphoneIcon"
              size="16"
              class="hover-purple"
              @click="resetAppKey(props.row)"
            />
          </span>

          <span v-else>
            <span v-b-tooltip.hover.top="$t('Management.Employees.GetAppKey')">
              <feather-icon
                icon="LinkIcon"
                size="16"
                class="hover-purple"
                @click="genAppKey(props.row)"
              />
            </span>
            <span
              v-b-tooltip.hover.top="$t('Management.Employees.GetUserGuide')"
              style="margin-left: 7px"
            >
              <feather-icon
                icon="FileTextIcon"
                size="16"
                class="hover-purple"
                @click="getPdf(props.row)"
              />
            </span>
          </span>
        </span>
      </span>

      <!-- Column: Common -->
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>

    <!-- pagination -->
    <template slot="pagination-bottom" slot-scope="props">
      <div class="d-flex justify-content-between flex-wrap px-2 pb-2">
        <div class="d-flex align-items-center mb-0 mt-1 flex-wrap">
          <span class="text-nowrap">
            {{ $t('Global.ShowingOneOf') }}
          </span>
          <v-select
            v-model="pageLength"
            class="mx-1"
            style="min-width: 75px"
            :clearable="false"
            :options="['10', '15', '30']"
            @input="value => props.perPageChanged({ currentPerPage: value })"
          />
          <span class="text-nowrap">{{ $t('Global.EntriesShown') }}</span>
        </div>
        <div>
          <b-pagination
            :value="1"
            :total-rows="props.total"
            :per-page="pageLength"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @input="value => props.pageChanged({ currentPage: value })"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </div>
    </template>
  </vue-good-table>
</template>

<script>
import { BPagination, BFormCheckbox } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'

export default {
  components: {
    VueGoodTable,
    vSelect,
    BFormCheckbox,
    BPagination,
  },

  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    searchTerm: {
      type: String,
      default: () => '',
    },
  },

  data() {
    return {
      pageLength: 10,
      columns: [
        {
          label: this.$t('Management.Employees.Username'),
          field: 'username',
        },
        {
          label: this.$t('Management.Employees.Firstname'),
          field: 'firstname',
        },
        {
          label: this.$t('Management.Employees.Lastname'),
          field: 'lastname',
        },
        {
          label: this.$t('Management.Employees.Email'),
          field: 'email',
        },
        {
          label: this.$t('Management.Employees.Phone'),
          field: 'phone',
        },
        {
          label: this.$t('Management.Employees.Contract.Contract'),
          field: 'contract',
        },
        {
          label: this.$t('Management.Employees.Status'),
          field: 'active',
          sortable: false,
        },
        {
          label: this.$t('Management.Employees.AppAccess'),
          field: 'appAccess',
          sortable: false,
        },
        {
          label: this.$t('Global.Action'),
          field: 'action',
          sortable: false,
        },
      ],
    }
  },

  methods: {
    searchFunc(_, col, cellValue, searchTerm) {
      if (col.field === 'contract') {
        return String(
          this.$t(`Management.Employees.Contract.Types.${cellValue}`),
        )
          .toLowerCase()
          .includes(String(searchTerm).toLowerCase())
      }
      return String(cellValue)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase())
    },
    formatContract({ contract }) {
      return this.$t(`Management.Employees.Contract.Types.${contract}`)
    },
    async resetAppKey({ id, firstname, lastname }) {
      const subject = `${firstname} ${lastname}`
      try {
        const messageVNode = this.$createElement('div', {
          class: ['p-1'],
          domProps: {
            innerHTML: this.$t('Management.Employees.ResetAppQuestion', {
              subject,
            }),
            style: 'font-size: 16px',
          },
        })

        const choice = await this.$bvModal.msgBoxConfirm([messageVNode], {
          title: this.$t('Management.Employees.ResetAppKey'),
          size: 'sm',
          okVariant: 'primary',
          cancelVariant: 'outline-secondary',
          okTitle: this.$t('Global.Deny'),
          cancelTitle: this.$t('Global.Retreat'),
          hideHeaderClose: false,
          centered: true,
          'body-class': 'p-2',
        })
        if (!choice) return
      } catch {
        return
      }

      try {
        await this.$axios.get(`auth/app/strip-access-key/${id}`)
        this.$alert.success(
          this.$t('Management.Employees.ResetAppPasswordResponse', {
            subject,
          }),
        )
        this.$emit('reset-app-key', id)
      } catch {
        this.$alert.error()
      }
    },

    async getPdf(row) {
      try {
        const { data } = await this.$axios.get(
          `auth/app/download-registration-pdf/${row.id}`,
          { responseType: 'blob' },
        )

        const url = window.URL.createObjectURL(new Blob([data]))

        // Create a temporary anchor element and trigger a download
        const link = document.createElement('a')
        link.href = url

        link.setAttribute(
          'download',
          `${moment().format('YYYYMMDD')}_Anleitung_${row.employeeNumber}_${
            row.firstname
          }_${row.lastname}.pdf`,
        )

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        // Clean up the URL object
        window.URL.revokeObjectURL(url)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Global.PdfDownloaded'),
            icon: 'BellIcon',
          },
        })
      } catch (error) {
        this.$alert.error()
      }
    },

    async genAppKey({ id }) {
      const getLink = async () => {
        try {
          return this.$axios.get(`auth/app/activation-token/${id}`)
        } catch (error) {
          this.$alert.error()
          return null
        }
      }

      const { data } = await getLink()

      if (!data) return

      try {
        await this.$copyText(data)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Global.Copied'),
            icon: 'BellIcon',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Global.CanCopy'),
            icon: 'BellIcon',
          },
        })
      }
    },
    edit(id) {
      this.$router.push({
        name: 'employee-edit',
        params: { id },
      })
    },
  },
}
</script>

<style scoped lang="scss">
span.appAccess span {
  &.switch-icon-right {
    color: #5e5873 !important;
  }
  &.switch-icon-left {
    color: #fff !important;
  }
}
</style>
